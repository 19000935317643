<template>
  <v-card
    no-gutter
    :style="
      'width: 100% !important; height: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
    class="align-center justify-center text-center hide-scrollbar"
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ $t("tagdata.name") + " - " + module.tag.name }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      class="weathertable hide-scrollbar"
      style="background: transparent"
      dense
      :search="search"
      :items-per-page="15"
    >
      <template v-slot:createdAt="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "RawTable",

  mixins: [Meta],

  props: ["module", "timerTick", "fakeData"],

  methods: {
    ...mapActions("tag", ["getKeysForTag"]),
    ...mapActions("tagData", { getData: "getTagDataAsync" }),

    async loadData() {
      let tagData = await this.getData({
        metrics: {
          tag: this.module.tag.deveui,
          limit: 100,
        },
      });

      await this.getKeysForTag({ tagId: this.module.tag.deveui });

      this.tagKeys = this.tagDataKeys.slice();

      for (let data of tagData) {
        let tk = this.tagKeys.find((td) => td.key == data.key);

        if (tk) data.label = tk.label ? tk.label : tk.key;
      }

      this.items = tagData.slice().reverse();
    },

    async getNewData() {
      let tagData = await this.getData({
        metrics: {
          tag: this.module.tag.deveui,
          from: this.fromDate.toISOString(),
        },
      });

      for (let t in tagData) {
        if (this.items.length >= 100) this.items.pop();

        let tk = this.tagKeys.find((td) => td.key == tagData[t].key);

        if (tk) tagData[t].label = tk.label ? tk.label : tk.key;

        this.items.unshift(tagData[t]);
      }

      this.fromDate = new Date();
    },
  },

  computed: {
    ...mapState("tagData", {
      tagDataKeys: "keys",
    }),
  },

  data() {
    return {
      headers: [
        { text: this.$t("tagdata.fields.key"), value: "label" },
        { text: this.$t("tagdata.fields.value"), value: "value" },
        { text: this.$t("tagdata.fields.createdAt"), value: "createdAt" },
      ],
      items: [],
      tagKeys: [],
      loading: true,
      search: null,
      fromDate: new Date(),
    };
  },

  async mounted() {
    this.loadData();
    this.loading = false;
  },

  watch: {
    timerTick(v) {
      if (v % 8 == 0) {
        this.getNewData();
      }
    },
  },
};
</script>

<style scoped>
.weathertable {
  height: 100%;
}
</style>
