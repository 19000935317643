<template>
  <v-card
    :style="
      'width: 100% !important; height: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ module ? module.title : "Google Maps" }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div style="width: 120px" v-if="module != undefined">
        <timed-progress :time="30" v-on:event-update="updateMarkers" />
      </div>
    </v-toolbar>

    <GmapMap
      :center="mapCenter"
      :clickable="true"
      :map-type-id="googleMapsViewType"
      @click="openSetPos"
      @dragend="dragEnd"
      @zoom_changed="zoomLevelChange"
      @center_changed="centerChange"
      @maptypeid_changed="mapTypeChange"
      style="width: 100%; height: 85% !important"
      ref="gmapRef"
    >
      <template v-slot:visible>
        <div
          class="search-box"
          :style="`
            background: ${searchHidden ? 'none' : '#ffffff90'};
            border-radius: 10px;
            right: 60px;
            top: 70px;
            max-width: 100%;
            min-height: 104px;
            height: auto;
            position: absolute;
            z-index: 999999;
          `"
        >
          <v-btn
            fab
            small
            @click="searchHidden = !searchHidden"
            color="primary"
            style="position: absolute; right: -45px; top: calc(50% - 22.5px)"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-container
            :style="`${
              searchHidden
                ? 'width: 0px; height: 0px'
                : 'width: 100%; height: 100%'
            }`"
          >
            <v-autocomplete
              v-model="filterNodes"
              :items="tags"
              chips
              item-text="name"
              return-object
              multiple
              light
              class="pl-2"
              :placeholder="$t('tag.filter')"
              :label="$t('tag.filter')"
              @change="fitToBounds"
              style="overflow: hidden"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  color="#2e4250"
                  close
                  @click="data.select"
                  @click:close="removeFilterNode(data.item)"
                  style="color: white"
                  >{{ data.item.name }}</v-chip
                >
              </template>
            </v-autocomplete>
          </v-container>
        </div>
      </template>
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWindowOpen"
        @closeclick="closeInfoWindow"
      >
        <v-container fluid>
          <v-card flat class="text-left" v-if="setPosForm">
            <v-card-title>{{ $t("map.setPosFormTitle") }}</v-card-title>
            <v-divider />
            <v-card-text>
              <v-autocomplete
                autocomplete="off"
                :loading="status.loading"
                :label="$t('alarm.fields.tag')"
                v-model="deveui"
                :items="tags"
                required
                item-text="name"
                item-value="deveui"
                prepend-icon="mdi-cellphone-link"
              ></v-autocomplete>
              <v-btn color="blue darken-1" text @click="submitPos()">
                {{ $t("common.save") }}
              </v-btn>
            </v-card-text>
          </v-card>

          <v-card flat class="text-left" v-else>
            <v-card-title>{{ currTag.name }}</v-card-title>
            <v-divider />
            <v-card-text>
              <h3>
                {{ $t("tag.fields.deveui") }}:
                <span class="subtitle-1">{{ currTag.deveui }}</span>
              </h3>
              <h3>
                {{ $t("tag.fields.profile") }}:
                <span class="subtitle-1">{{ currTag.profile }}</span>
              </h3>

              <h3>
                {{ $t("tag.fields.name") }}:
                <span class="subtitle-1">{{ currTag.name }}</span>
              </h3>

              <h3>
                {{ $t("tag.fields.appKey") }}:
                <span class="subtitle-1">{{ currTag.appKey }}</span>
              </h3>

              <h3 v-if="address == ''">
                {{ $t("tag.fields.latAndLng") }}:
                <span class="subtitle-1">
                  {{ currTag.latitude + " - " + currTag.longitude }}
                </span>
              </h3>
              <h3 v-else>
                {{ $t("common.address") }}:
                <span class="subtitle-1">
                  {{ address }}
                </span>
              </h3>

              <v-divider />
              <h3>
                {{ $t("tagdata.latestvalue") }}:
                <span class="subtitle-1">
                  {{ tdata !== null ? tdata.value : "N/A" }}
                </span>
              </h3>
              <h3>
                {{ $t("tagdata.fields.key") }}:
                <span class="subtitle-1">
                  {{ tdata !== null ? tdata.key : "N/A" }}
                </span>
              </h3>
              <h3>
                {{ $t("common.timestamp") }}:
                <span class="subtitle-1">
                  {{ tdata !== null ? humanDate(tdata.createdAt) : "N/A" }}
                </span>
              </h3>
            </v-card-text>

            <v-card-text class="ma-0 pa-0">
              <v-img
                v-if="
                  currTag.installationImage && this.SO_API_BASE_URL != undefined
                "
                :src="
                  this.SO_API_BASE_URL + '/image/' + currTag.installationImage
                "
              ></v-img>
              <v-btn
                v-if="mode == 1"
                color="error"
                text
                @click="confirmDelete = true"
              >
                {{ $t("common.delete") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-container>
      </GmapInfoWindow>

      <GmapCluster :max-zoom="15">
        <GmapMarker
          :key="m.deveui"
          :label="{ text: m.name, color: 'rgb(254, 254, 254)' }"
          v-for="m in computedTags"
          :position="{
            lat: m.latitude !== null ? m.latitude : 0.0,
            lng: m.longitude !== null ? m.longitude : 0.0,
          }"
          :clickable="true"
          :draggable="false"
          @click="
            toggleInfoWindow(m);
            center = {
              lat: m.latitude !== undefined ? m.latitude : 0.0,
              lng: m.longitude !== undefined ? m.longitude : 0.0,
            };
          "
          v-on:dragend="updateTagPos(m)"
          v-on:position_changed="updatePos($event)"
          :icon="markerIcon(m.deveui)"
        />
      </GmapCluster>
    </GmapMap>
    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="removePosition"
      v-on:cancel-dialog="confirmDelete = false"
    />
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import { gmapApi } from "vue2-google-maps";
import TimedProgress from "@/components/common/TimedProgress.vue";
import Meta from "@/_helpers/ModuleMeta";
import Enum from "@/_helpers/Enum";
import DeleteDialog from "@/components/common/DeleteDialog";

export default {
  name: "TagMap",
  mixins: [Meta],
  props: ["module", "mode"],

  data() {
    return {
      searchHidden: true,
      filterNodes: [],
      mapCenter: { lat: 58.3, lng: 14 },
      infoWindowPos: null,
      infoCurrMidIdx: null,
      setPosForm: false,
      infoWindowOpen: false,
      currTag: {},
      currPos: {},
      deveui: null,
      lastDate: "N/A",
      tdata: null,
      zoomLevel: 8,
      tempZoom: 7,
      tempCenter: { lat: 58.3, lng: 14 },
      address: "",
      confirmDelete: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -25,
        },
      },

      markerIcons: {
        green: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        red: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      },
    };
  },
  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapState("tag", ["tags", "status"]),
    ...mapState("tagData", { tagDataStatus: "status", tagData: "tagData" }),
    ...mapState("alarms", ["activeAlarms"]),
    ...mapState("settings", [
      "googleMapsZoom",
      "googleMapsLatLng",
      "googleMapsViewType",
    ]),

    google: gmapApi,

    computedTags() {
      if (this.filterNodes.length > 0) return this.filterNodes;

      return this.tags.filter(
        (e) =>
          e.deveui != null &&
          ((e.latitude && e.longitude) ||
            (parseFloat(e.latitude) >= 1 && parseFloat(e.longitude) >= 1))
      );
    },
  },

  methods: {
    ...mapActions("configuration", ["getBaseUrl"]),
    ...mapActions("tag", ["getTags", "updateTag", "getKeysForTag"]),
    ...mapActions("tagData", [
      "getTagDataWithMetrics",
      "getTagDataAsync",
      "clearTagData",
    ]),
    ...mapActions("settings", [
      "setGoogleMapsZoom",
      "setLatnLng",
      "setMapType",
    ]),

    openSetPos(lnl) {
      if (this.mode !== Enum.DashboardMode.EDIT) return;

      this.setPosForm = true;
      this.infoWindowOpen = true;
      this.infoWindowPos = lnl.latLng;
    },

    removePosition() {
      if (this.currTag == null) return;
      this.currTag.latitude = null;
      this.currTag.longitude = null;

      this.updateTag({ payload: this.currTag, fetch: false });

      this.confirmDelete = false;
      this.closeInfoWindow();
    },

    submitPos() {
      if (this.deveui == null) return;

      let tag = null;
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].deveui == this.deveui) {
          tag = this.tags[i];
        }
      }

      this.currPos = {
        latitude: this.infoWindowPos.lat(),
        longitude: this.infoWindowPos.lng(),
      };

      this.updateTagPos(tag);
      this.deveui = null;
      this.closeInfoWindow();
    },

    closeInfoWindow() {
      this.setPosForm = false;
      this.infoWindowOpen = false;
    },

    updatePos(event) {
      let lng = event.lng();
      let lat = event.lat();

      this.currPos = { latitude: lat, longitude: lng };
    },

    updateTagPos(m) {
      if (m !== undefined) {
        m.latitude = this.currPos.latitude;
        m.longitude = this.currPos.longitude;
        this.updateTag({ payload: m, fetch: false });
      }
    },

    markerIcon(deveui) {
      let url = this.markerIcons.green;

      if (this.activeAlarms.length > 0) {
        var hasAlarm = this.activeAlarms.find((x) => {
          if (x.tag.deveui === deveui) {
            return true;
          }
        });

        if (hasAlarm) {
          url = this.markerIcons.red;
        }
      }

      return { url: url, labelOrigin: { x: 16, y: -15 } };
    },

    async toggleInfoWindow(marker) {
      this.address = "";
      this.setPosForm = false;
      this.currTag = marker;
      let bound = new this.google.maps.LatLngBounds();
      bound.lat = marker.latitude;
      bound.lng = marker.longitude;
      this.infoWindowPos = bound;
      this.address = await this.reverseGeoCode(bound);

      // Get last value
      let td = await this.getTagDataAsync({
        metrics: {
          tag: marker.deveui,
          from: new Date(
            new Date().getTime() - 72 * 60 * 60 * 1000
          ).toISOString(),
          limit: 1,
        },
      });

      if (td[0] !== undefined) {
        this.tdata = td[0];
        this.lastDate = this.humanDate(td[0].createdAt);
      }

      // The same tag clicked toggle
      if (this.infoCurrMidIdx == marker.deveui) {
        this.infoWindowOpen = false;
      } else {
        this.infoWindowOpen = true;
        this.infoCurrMid = marker.deveui;
      }
    },

    handleCloseEvent() {
      this.infoWindowOpen = false;
      this.setPosForm = false;
      this.currTag = {};
      this.tdata = null;
    },

    initBounds(bound = null) {
      this.$refs.gmapRef.$mapPromise.then(() => {
        this.$refs.gmapRef.$mapObject.maxZoom = 8;
        let bounds = new this.google.maps.LatLngBounds();
        if (this.googleMapsLatLng == null) {
          if (bound === null) {
            this.computedTags.forEach((e) => {
              bounds.extend({
                lat: e.latitude || 0.0,
                lng: e.longitude || 0.0,
              });
            });
          } else {
            bounds.extend(bound);
          }

          this.$refs.gmapRef.$mapObject.fitBounds(bounds);
        } else {
          bounds.extend(this.googleMapsLatLng);
          this.$refs.gmapRef.$mapObject.setCenter(this.googleMapsLatLng);
          this.$refs.gmapRef.$mapObject.setZoom(this.googleMapsZoom);
        }
      });
    },

    fitToBounds() {
      this.$refs.gmapRef.$mapPromise.then(() => {
        this.$refs.gmapRef.$mapObject.maxZoom = 8;
        let bounds = new this.google.maps.LatLngBounds();
        this.computedTags.forEach((e) => {
          bounds.extend({
            lat: e.latitude || 0.0,
            lng: e.longitude || 0.0,
          });
        });

        this.$refs.gmapRef.$mapObject.fitBounds(bounds);
      });
    },

    removeFilterNode(item) {
      if (item == null && item.deveui == undefined) return;

      let index = this.filterNodes.findIndex((e) => e.deveui == item.deveui);
      this.filterNodes.splice(index, 1);
      this.fitToBounds();
    },

    async updateMarkers() {
      await this.getTags();
    },

    panToBound(bound = null) {
      this.$refs.gmapRef.$mapPromise.then(() => {
        let bounds = new this.google.maps.LatLngBounds();
        bounds.extend(bound);

        this.$refs.gmapRef.$mapObject.panTo(bounds);
        this.$refs.gmapRef.$mapObject.setZoom(this.googleMapsZoom);
      });
    },

    zoomLevelChange(val) {
      this.tempZoom = val;
    },

    centerChange(val) {
      if (val == undefined) return;

      this.tempCenter = { lat: val.lat(), lng: val.lng() };
    },

    mapTypeChange(val) {
      this.setMapType(val);
    },

    dragEnd() {
      this.setLatnLng(this.tempCenter);
      this.setGoogleMapsZoom(this.tempZoom);
    },
  },

  async created() {
    await this.getBaseUrl();
    await this.getTags();
  },

  async mounted() {
    await this.$gmapApiPromiseLazy();
    this.initBounds();
  },

  components: {
    DeleteDialog,
    GmapCluster,
    TimedProgress,
  },

  watch: {},
};
</script>

<style>
.gm-style
  > div
  > div
  > div
  > div
  > div
  > div
  > div[style*="color: rgb(254, 254, 254)"] {
  line-height: 20px;
  font-size: 18px !important;
  font-weight: 700;
  color: white !important;
  background: #00000055;
  padding: 0.2rem;
}
</style>
