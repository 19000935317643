<template>
  <v-card
    :style="
      'width: 100% !important; height: 100% !important; overflow: hidden' +
      backgroundColor
    "
  >
    <v-row
      v-if="!multiSensor"
      :style="`${
        !horizontalTitle ? 'display: grid; align-items: start;' : ''
      } height: 100%; margin: 0; `"
    >
      <v-col
        class="d-flex justify-content-left"
        :style="`${
          horizontalTitle
            ? 'height: 100%; align-items: center;'
            : 'align-content: top; align-items: top;'
        } ${textColor} ${titleBackground};`"
      >
        <div>
          <v-row no-gutters>
            <span
              :style="`width: 100%; font-size: ${fontStyle.title.size}rem`"
              :class="fontStyle.title.weight + ' text-left'"
            >
              {{ module.name === null ? "" : module.title }}
            </span>
          </v-row>
          <v-row no-gutters>
            <span
              :v-if="!multiSensor"
              :style="`width: 100%; font-size: ${
                fontStyle.title.size * 0.2 + 0.6
              }rem`"
              :class="fontStyle.title.weight + ' text-left'"
              >{{
                singleSeries[0] ? humanDate(singleSeries[0].createdAt) : "N/A"
              }}</span
            >
          </v-row>
        </div>
      </v-col>
      <v-col
        :cols="horizontalTitle ? 6 : 12"
        class="d-flex align-center justify-center flex-wrap"
        :style="`
            padding: 0;
            ${horizontalTitle ? 'height: 100%;' : ''}`"
      >
        <div>
          <v-row no-gutters>
            <span
              :class="`text-center
               ${fontStyle.value.weight}`"
              :style="
                'width: 100%;' +
                textColor +
                ';font-size: ' +
                fontStyle.value.size +
                'rem'
              "
            >
              {{
                singleSeries[0]
                  ? getValue(
                      singleSeries[0].tagDeveui,
                      singleSeries[0].key,
                      singleSeries[0].value
                    )
                  : "N/A"
              }}

              <v-icon
                :medium="horizontalTitle"
                :large="!horizontalTitle"
                :style="textColor"
                v-if="metaHasKey(module.meta, MetaEnum.ICON)"
                >{{ getMeta(module.meta, MetaEnum.ICON).value }}</v-icon
              >
              <v-icon
                :medium="horizontalTitle"
                :large="!horizontalTitle && fontStyle.value.size < 2.8"
                :x-large="!horizontalTitle && fontStyle.value.size > 2.8"
                size=""
                :color="
                  singleSeries[0] && singleSeries[1]
                    ? getTrendColor(
                        singleSeries[0].value,
                        singleSeries[1].value
                      )
                    : getTrendColor(null, null)
                "
                v-if="!metaHasKey(module.meta, MetaEnum.HIDETRENDICON)"
                >{{
                  singleSeries[0] && singleSeries[1]
                    ? getTrendIcon(singleSeries[0].value, singleSeries[1].value)
                    : getTrendIcon(null, null)
                }}</v-icon
              >
            </span>
          </v-row>
          <v-row no-gutters align="start">
            <span
              class="text-center"
              :style="
                'width: 100%;' +
                textColor +
                ';font-size: ' +
                (fontStyle.value.size * 0.2 + 0.5) +
                'rem'
              "
              v-if="!metaHasKey(module.meta, MetaEnum.SINGLEHIDENODEINFO)"
            >
              {{
                `${singleSeries[0] ? singleSeries[0].tag.name : "N/A"} - ${
                  singleSeries[0]
                    ? singleSeries[0].label
                      ? singleSeries[0].label
                      : "N/A"
                    : "N/A"
                }`
              }}
            </span>
          </v-row>
        </div>

        <div v-if="hasSparkline" class="d-block mt-2" style="width: 100%">
          <sparkline
            :sparkline-data="
              singleSeries.map((it) => ({
                value: it.value,
              }))
            "
            :color="sparkLineColor"
            :label-color="sparkLineLabelColor"
          />
        </div>
      </v-col>
    </v-row>
    <!-- Multiple sensors -->
    <div v-else style="height: 100%" class="py-0 d-flex flex-column">
      <div>
        <v-col
          no-gutters
          align="start"
          :style="`height: 48px; ${textColor} ${titleBackground}`"
        >
          <span
            :style="`width: 100%; font-size: ${fontStyle.title.size}rem`"
            :class="fontStyle.title.weight + ' text-left'"
          >
            {{ module.name === null ? "" : module.title }}
          </span>
        </v-col>
      </div>
      <div v-if="data.length > 0">
        <div
          v-for="(item, index) in data"
          :key="index"
          style="height: 100%; max-width: 100%; padding: 0 12px"
        >
          <v-row style="height: 100%">
            <v-col no-gutters class="pa-1 d-flex justify-center flex-column">
              <span
                class="text-left pl-2"
                :style="
                  'width: 100%;' +
                  textColor +
                  ';font-size: ' +
                  (fontStyle.value.size * 0.2 + 0.7) +
                  'rem'
                "
              >
                {{
                  !metaHasKey(module.meta, MetaEnum.SINGLEHIDENODEINFO)
                    ? `${item[0].tag.name} - ${item[0].label}`
                    : `${item[0].label}`
                }}
              </span>
              <span
                class="text-left pl-2"
                :style="`width: 100%; font-size: ${
                  fontStyle.title.size * 0.2 + 0.6
                }rem`"
                :class="fontStyle.title.weight + ' text-left'"
                >{{ humanDate(item[0].createdAt) }}</span
              >
            </v-col>
            <v-col no-gutters class="pa-1 d-flex justify-center flex-column">
              <span
                :class="`text-center
               ${fontStyle.value.weight}`"
                :style="
                  'width: 100%;' +
                  textColor +
                  ';font-size: ' +
                  fontStyle.value.size +
                  'rem'
                "
              >
                {{ getValue(item[0].tagDeveui, item[0].key, item[0].value) }}

                <v-icon
                  :medium="horizontalTitle"
                  :style="textColor"
                  v-if="metaHasKey(module.meta, MetaEnum.ICON)"
                  >{{ getMeta(module.meta, MetaEnum.ICON).value }}</v-icon
                >
                <v-icon
                  :large="fontStyle.value.size < 2.8"
                  :x-large="fontStyle.value.size > 2.8"
                  size=""
                  :color="
                    getTrendColor(
                      item[0].value,
                      item[1] ? item[1].value : item[0].value
                    )
                  "
                  v-if="!metaHasKey(module.meta, MetaEnum.HIDETRENDICON)"
                  >{{
                    getTrendIcon(
                      item[0].value,
                      item[1] ? item[1].value : item[0].value
                    )
                  }}</v-icon
                >
              </span>
            </v-col>
            <div v-if="hasSparkline" class="d-block mt-2" style="width: 100%">
              <sparkline
                :sparkline-data="
                  item.map((it) => ({
                    value: it.value,
                  }))
                "
                :color="sparkLineColor"
                :label-color="sparkLineLabelColor"
              />
            </div>
          </v-row>
          <v-divider v-if="index < data.length - 1" />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Meta from "@/_helpers/ModuleMeta";
import FontMeta from "@/_helpers/FontMeta";
import Sparkline from "@/components/chart/Sparkline.vue";

export default {
  name: "ModuleSingle",

  components: { Sparkline },
  mixins: [Meta],

  data() {
    return {
      posTrend: null,
      tdata: 0.0,
      prevVal: null,

      backgroundColor: "",
      titleBackground: ";background-color: transparent;",
      textColor: "",
      fontStyle: {
        title: { size: 1.17, weight: "font-weight-bold" },
        value: { size: 2.125, weight: "font-weight-regular" },
      },

      keyLabel: "",
      name: "",

      horizontalTitle: false,
      multiSensor: false,
      data: [],
      decimalPoints: 3,
    };
  },

  props: ["module", "timerTick", "fakeData"],

  methods: {
    ...mapActions("tagData", {
      getData: "getTagDataWithMetrics",
      getMultiData: "getTagDataForModuleByLimit",
    }),

    async loadData() {
      let limit = 2;

      if (this.hasSparkline) limit = 10;

      this.data = await this.getMultiData({
        moduleId: this.module.dashboardModuleId,
        limit: limit,
      });
    },

    loadFakeData() {
      if (this.getMeta(this.module.meta, "so_deveui_key"))
        this.multiSensor = true;
      else this.multiSensor = false;

      let digits = this.getMeta(
        this.module.meta,
        this.MetaEnum.DECIMAL_ROUNDING
      );
      digits = digits != null && digits.value <= 6 ? digits.value : 3;

      let baseVal = 21.21879;
      let data = [];
      for (let i = 0; i < (this.multiSensor ? 3 : 1); i++) {
        let series = [
          {
            value: +parseFloat(baseVal + i * 11.1332).toFixed(digits),
            label: !this.multiSensor ? "Sensor" : "Sensor " + (i + 1),
            tag: { name: "Node" },
            createdAt: Date.now(),
          },
        ];

        for (let i = 0; i < 9; i++)
          series.push({
            value: 10 + Math.floor(Math.random() * 20),
            createdAt: Date.now(),
          });

        data.push(series);
      }

      this.data = data;
    },

    setStyles() {
      if (this.module && this.module.meta) {
        this.backgroundColor = "";
        this.titleBackground = ";background-color: transparent;";
        this.textColor = "";
        for (let i in this.module.meta) {
          var currentMeta = this.module.meta[i];
          if (currentMeta.key == "so_style_meta_singel_background")
            this.backgroundColor = ";background-color: " + currentMeta.content;

          if (currentMeta.key == "so_style_meta_singel_textcolor")
            this.textColor = ";color: " + currentMeta.content;

          if (currentMeta.key == "so_style_meta_singel_title_background")
            this.titleBackground = ";background-color: " + currentMeta.content;

          if (currentMeta.key == "so_horizontel_single_title")
            this.horizontalTitle = true;

          if (currentMeta.key == "so_font_style_large") {
            if (currentMeta.valueMatch == FontMeta.types.title.value) {
              this.fontStyle.title.size = currentMeta.value;
              this.fontStyle.title.weight = currentMeta.content;
            } else if (currentMeta.valueMatch == FontMeta.types.value.value) {
              this.fontStyle.value.size = currentMeta.value;
              this.fontStyle.value.weight = currentMeta.content;
            }
          }
        }
      }
    },

    getValue(deveui, tagkey, value) {
      if (value === undefined || value === null) return "N/A";

      let parsedValue = +parseFloat(value).toFixed(this.decimalPoints);

      if (!parsedValue) parsedValue = value;

      // Check if the type is named value
      if (this.module.meta) {
        for (let i = 0; i < this.module.meta.length; i++) {
          if (
            this.module.meta[i].key == "so_value_match" &&
            (parseFloat(this.module.meta[i].valueMatch) == value ||
              this.module.meta[i].valueMatch === value)
          )
            return this.module.meta[i].value;
        }

        let prefix = this.module.meta.find(
          (m) =>
            m.key == Meta.Enum.PREFIX &&
            m.valueMatch == tagkey &&
            m.content == deveui
        );
        let suffix = this.module.meta.find(
          (m) =>
            m.key == Meta.Enum.SUFIX &&
            m.valueMatch == tagkey &&
            m.content == deveui
        );

        let str = [];
        if (prefix) str.push(prefix.value);
        str.push(parsedValue);
        if (suffix) str.push(suffix.value);

        return str.join(" ");
      }

      return parsedValue;
    },

    getTrendColor(cVal, pVal) {
      if (cVal === null || pVal == null) return "yellow";

      if (+parseFloat(cVal) > +parseFloat(pVal)) return "green";
      else if (+parseFloat(cVal) > +parseFloat(pVal)) return "red";
      else return "yellow";
    },

    getTrendIcon(cVal, pVal) {
      if (cVal === null || pVal == null) return "mdi-trending-neutral";

      if (+parseFloat(cVal) > +parseFloat(pVal)) return "mdi-trending-up";
      else if (+parseFloat(cVal) > +parseFloat(pVal))
        return "mdi-trending-down";
      else return "mdi-trending-neutral";
    },
  },

  computed: {
    ...mapState("tagData", ["tagData"]),
    ...mapState("tag", ["currentLabel"]),

    MetaEnum() {
      return Meta.Enum;
    },

    hasSparkline() {
      if (this.module.meta) {
        let sparkline = this.getMeta(
          this.module.meta,
          Meta.Enum.SINGLESPARKLINE
        );

        return sparkline == null ? false : true;
      }
      return false;
    },

    sparkLineColor() {
      let result = this.getMeta(
        this.module.meta,
        "so_style_meta_single_sparkline_color"
      );

      if (result == null) return "default";

      return result.content;
    },

    sparkLineLabelColor() {
      let result = this.getMeta(
        this.module.meta,
        "so_style_meta_single_sparkline_label_color"
      );
      if (result == null) return "default";

      return result.content;
    },

    singleSeries() {
      if (this.data[0]) return this.data[0];
      else return [];
    },
  },

  async created() {
    if (!this.fakeData) {
      if (this.getMeta(this.module.meta, "so_deveui_key"))
        this.multiSensor = true;

      await this.loadData();
      // Check if rounding is defined in module meta, default to 3 digits
      let digits = this.getMeta(
        this.module.meta,
        this.MetaEnum.DECIMAL_ROUNDING
      );

      // Make sure rounding is a valid number and reasonable
      this.decimalPoints =
        digits != null && digits.value <= 6 ? digits.value : 3;
    } else await this.loadFakeData();
    this.setStyles();
  },

  watch: {
    async timerTick() {
      await this.loadData();
      this.setStyles();
    },

    async module(val, oldVal) {
      if (val != oldVal) {
        if (this.fakeData) {
          await this.loadFakeData();
          this.setStyles();
        }
      }
    },
  },
};
</script>

<style scoped>
.date-bottom {
  bottom: 0;
  position: absolute;
}
</style>
